<template>
  <div class="columns is-multiline">
    <div class="column is-12">
      <div class="block">
        <img class="not_data_img" src="@/assets/not_data.png" alt="Sin Datos">
      </div>
      <div class="block mb-6">
        <p class="paragraph has-text-centered">
          {{ title }}
        </p>
      </div>
      <div class="block has-text-centered">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseNotData',
  props: {
    title: {
      type: String,
      default: 'No hay datos para mostrar'
    }
  }
}
</script>

<style lang="scss" scoped>
  .not_data_img {
    width: 100px;
    margin: auto;
    display: block;
  }
</style>
